.projects-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, auto);
  grid-column: 2 / span 10;
  max-width: 100%;
  padding-top: 250px;
}

.projects-title {
  padding-bottom: 40px;
}

.project-image {
  grid-row: 2;
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  padding: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.project-card {
  position: relative;
}

.menu-container {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
}

.project-description {
  text-align: left;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: #1b4c6f;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px;
  margin-bottom: 70px;
}

.project-links {
  text-align: right;
}

.website {
  padding: 20px;
  color: #def9ed;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.github {
  padding: 20px;
  color: #def9ed;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.project-text {
  text-align: left;
}

.hidden {
  opacity: 0;
  transform: translateX(0);
  transition: all 0.5s ease-out;
}

.slide-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.8s ease-out;
}

.slide-in-right {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.8s ease-out;
}

.project-card.slide-in-left.hidden {
  transform: translateX(-100%);
}

@media (min-width: 800px) {
  .projects-container {
    grid-column: 4 / span 6;
  }
}
