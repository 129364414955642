.hero-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-column: 3 / span 8;
  max-width: 100%;
  align-items: start;
  padding-top: 150px;
}

.hero-title {
  z-index: 2;
  grid-column: 3 / span 2;
  grid-row: 1;
  font-size: 68px;
}

.links {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column: 5 / span 2;
  grid-row: 2 / span 3;
  justify-items: end;
  gap: 50px;
}

.links a {
  font-size: 35px;
}

.hero-text {
  text-align: left;
  z-index: 2;
  grid-column: 1 / span 3;
  grid-row: 3 / span 2;
  /* backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px); */
  background-color: #1b4c6f;
  padding: 1rem;
  border-radius: 8px;
}

@media (max-width: 1109px) {
  .hero-container {
    padding-top: 100px;
  }

  .hero-title {
    grid-column: 2 / span 4;
  }

  .links a {
    font-size: 30px;
  }
}

@media (max-width: 673px) {
  .hero-title {
    z-index: 2;
    grid-column: 3 / span 3;
    grid-row: 1;
    font-size: 36px;
  }

  .links {
    grid-row: 2 / span 3;
    gap: 15px;
  }

  .links a {
    font-size: 20px;
  }

  .headshot {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
  }

  .hero-text {
    grid-column: 1 / span 5;
    grid-row: 3 / span 5;
  }
}

@media (max-width: 490px) {
  .hero-container {
    grid-template-rows: auto;
  }

  .hero-title {
    z-index: 2;
    grid-column: 2 / span 4;
    grid-row: 1;
    font-size: 36px;
  }

  .links {
    padding-top: 100px;
    grid-row: 1 / span 2;
    gap: 15px;
  }

  .links a {
    font-size: 16px;
  }

  .headshot {
    width: 250px;
    grid-column: 1 / span 5;
    grid-row: 1 / span 2;
  }

  .hero-text {
    grid-column: 1 / span 5;
    grid-row: 3 / span 5;
  }
}
