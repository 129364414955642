.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
}

h1 {
  color: #def9ed;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
}

p {
  color: #def9ed;
  font-family: "Anta", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

a {
  color: #adefd1;
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #00203f;
}
@media (min-width: 1170px) {
  p {
    font-size: 26px;
  }
}
