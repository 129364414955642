.experience-container {
  padding-top: 100px;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 2 / span 10;
  max-width: 100%;
}

.skills-content {
  grid-column: span 2;
}

.skills-title {
  grid-column: 1 / span 2;
  text-align: left;
  padding-bottom: 40px;
}

.education-content {
  grid-column: 3 / span 2;
}

.education-title {
  grid-column: 3 / span 2;
  text-align: left;
  padding-bottom: 40px;
}

.p-title {
  text-align: left;
  padding: 10px;
  font-size: 20px;
  text-decoration: underline;
}

.p-content {
  display: inline-block;
  text-align: center;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(0, 32, 63, 0.7);
  border-radius: 8px;
  padding: 10px;
}

.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in {
  opacity: 1;
  transform: translateY();
}

@media (max-width: 900px) {
  .experience-container {
    grid-template-columns: 1fr;
    grid-row: repeat(2, auto);
  }

  .skills-title {
    grid-column: 1;
  }

  .skills-content {
    grid-column: 1;
  }

  .education-content {
    grid-column: 1;
    grid-row: 2;
  }

  .education-title {
    grid-column: 1;
  }
}
