/* @keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.contact-container {
  /* animation: fade linear both;
  animation-timeline: view();
  animation-range: entry 40% cover 40%; */
  padding-top: 100px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column: 4 / span 5;
  max-width: 100%;
}

.contact-title {
  padding-left: 60px;
  padding-bottom: 40px;
}

@media (max-width: 677px) {
  .contact-container {
    grid-column: 1 / span 10;
  }
}
