.footer-container {
    padding-top: 50px;
    display: grid;
    gap: .5rem;
    grid-column: 2 / span 10;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    width: 100%;
}

.footer-links{
    grid-row: 1;
    text-align: center;
}

.footer-social{
    grid-row: 2;
    text-align: center;
}

.footer-copyright{
    grid-row: 3;
    text-align: center;
    padding-bottom: 50px
}