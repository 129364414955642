* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

